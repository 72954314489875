import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {


  alert(message: string, type: 'warning' | 'success' | 'error', duraion?: number) {
    return Swal.fire({
      title: message,
      icon: type,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: duraion ? duraion : 5000,
      timerProgressBar: true,
    });
  }

  // SHOW ALERT AS A POPUP WHEN REQUIRED
  alertModal(message: string, type: any, CancelButton = false, isIcon?: boolean) {
    return Swal.fire({
      text: message,
      icon: isIcon ? type : null,
      showConfirmButton: true,
      showCancelButton: CancelButton,
      confirmButtonText: 'Ok'
    });
  }
  confirmModal(message: string, type: any, CancelButton = false, isIcon?: boolean, confirmText?: string, cancelText?: string ) {
    return Swal.fire({
      text: message,
      icon: isIcon ? type : null,
      showConfirmButton: true,
      showCancelButton: CancelButton,
      confirmButtonText: confirmText? confirmText : 'Ok',
      cancelButtonText: cancelText ? cancelText : 'Cancel'
    });
  }
}
