import { Injectable, WritableSignal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public __authStep: WritableSignal<number> = signal(0);
  public contactPage: WritableSignal<number> = signal(0);
  public emailPage: WritableSignal<number> = signal(0);
}
