import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from './event.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private storage = inject(StorageService);
  private event = inject(EventService);
  private router = inject(Router);


  public async logout(): Promise<void> {
    this.storage.clearUser();
    this.event.user.next({});
    this.event.login.next(false);
    this.event.courseDetails.next({})
    this.event.specificRound.next({})
    this.router.navigate(['/login'])
    this.event.requests = [];
    this.event.isHttpRequest.next(false)
    this.event.routeURL.set('')
  }
}
