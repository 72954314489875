import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID, inject } from '@angular/core';
import * as CryptoTS from 'crypto-ts';
import { CookieService } from 'ngx-cookie-service';
import { REQUEST } from 'src/express.tokens';
// import { SsrCookieService } from 'ngx-cookie-service-ssr';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  REMEMBER = {
    KEY: 'bts_auth02',
    PASSWORD: '!##0895*()?:}95047834&&tesdsfdsfsdf@EWdsd'
  };

  TEMP = {
    KEY: 'bts_auth',
    PASSWORD: '90590348534YYIU!@00asfdadsf@£sxfcdf'
  };
  USER = {
    KEY: 'bts_auth0',
    PASSWORD: '!##0895*()?:}95047834&&tes12323?ADSLklo'
  };

  private platformID = inject(PLATFORM_ID);
  private cookie = inject(CookieService);
  // private request = inject(REQUEST);
  constructor(
    @Optional() @Inject(REQUEST) private request: any
  ) { }

  // DECRYPT THE COOKIES DATA BEFORE SAVE
  encryption(data: any, secret: string) {
    return CryptoTS.AES.encrypt(JSON.stringify(data), secret).toString();
  }

  // DECRYPT THE COOKIES DATA
  decryption(data: any, secret: string) {
    const bytes = CryptoTS.AES.decrypt(data.toString(), secret);
    return JSON.parse(bytes.toString(CryptoTS.enc.Utf8));
  }

  //  SAVE USER COOKIES DATA OR LOGIN THE USER
  async setUser(data: any) {
    return this.cookie.set(this.USER.KEY, this.encryption(data, this.USER.PASSWORD).toString(), 7, '/', '', false, 'Strict');
  }

  // GET SAVED USER COOKIES DATA
  getUser() {
    const DATA = this.cookie.get(this.USER.KEY) !== null ? this.cookie.get(this.USER.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decryption(DATA, this.USER.PASSWORD);
    } else {
      return undefined;
    }
  }

  // CLEAR USER COOKIES DATA OR LOGOUT THE USER
  clearUser() {
    this.cookie.delete(this.USER.KEY, '/');
    this.cookie.delete(this.USER.KEY, '/user');
    this.cookie.delete(this.USER.KEY, '/user/*');
  }

  // GET USER COOKIES DATA BY KEY NAME
  getDataField(type: string) {
    if (type === 'token' && isPlatformServer(this.platformID)) {
      if (typeof this.request === 'string') {
        return this.decryption(this.request, this.USER.PASSWORD)[type];
      }
      return false
    } else {
      return this.getUser() && this.getUser()[type];
    }

  }

  // DETECT USER'S TOKEN IS AVAILABLE OR NOT
  isAuthenticate(): boolean {
    // let envToken = false;
    // if (this.request && typeof this.request !== 'undefined') {
    //   envToken = this.request['token']
    // }
    // const _user = envToken && this.decryption(envToken, this.USER.PASSWORD);
    // console.log(this.request , 'this.request ');
    if (isPlatformBrowser(this.platformID)) {
      return !!this.getDataField('token');
    } else {
      return !!this.request
    }

  }


  // SAVE REMEMBER ME OPTION IF EXIST
  setCredential(data: any) {
    return this.cookie.set(this.REMEMBER.KEY, this.encryption(data, this.REMEMBER.PASSWORD).toString(), 365, '/');
  }

  // GET SAVED REMEMBER ME OPTION IF EXIST
  getCredential() {
    const DATA = this.cookie.get(this.REMEMBER.KEY) !== null ? this.cookie.get(this.REMEMBER.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decryption(DATA, this.REMEMBER.PASSWORD);
    } else {
      return false;
    }
  }

  // DELETE SAVED REMEMBER ME OPTION
  clearCredential() {
    this.cookie.delete(this.REMEMBER.KEY, '/');
  }


  // SAVE TEMP DATA WHEN REQUIRED
  setTempData(data: any) {
    return this.cookie.set(this.TEMP.KEY, this.encryption(data, this.TEMP.PASSWORD).toString());
  }

  // GET SAVED TEMP DATA
  getTempData() {
    const DATA = this.cookie.get(this.TEMP.KEY) !== null ? this.cookie.get(this.TEMP.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decryption(DATA, this.TEMP.PASSWORD);
    } else {
      return false;
    }
  }

  // DELETE ANY TEMP DATA IF EXIST
  clearTempData() {
    return this.cookie.delete(this.TEMP.KEY);
  }

}
