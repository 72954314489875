import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {

  private storage = inject(StorageService);

  isHttpRequest = new BehaviorSubject<boolean>(false);
  isMapLoading = new BehaviorSubject<boolean>(false);

  user = new BehaviorSubject<any>({});
  userDetails = this.user.asObservable();

  login:BehaviorSubject<boolean> = new BehaviorSubject(this.storage.isAuthenticate());

  isProgress: BehaviorSubject<any> = new BehaviorSubject(0);
  progress = this.isProgress.asObservable();

  isLoader: BehaviorSubject<boolean> = new BehaviorSubject(true);


  userData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  courseDetails: BehaviorSubject<any> = new BehaviorSubject<any>({});
  register: BehaviorSubject<any> = new BehaviorSubject<any>({});

  modalOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  totalDetails: BehaviorSubject<any> = new BehaviorSubject<any>({});

  specificRound: BehaviorSubject<any> = new BehaviorSubject<any>({})
  shotType: BehaviorSubject<any> = new BehaviorSubject<any>({})
  settingsList: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  weather: BehaviorSubject<any> = new BehaviorSubject<any>({});
  courseFetch: BehaviorSubject<any> = new BehaviorSubject<any>({});


  headerElement: WritableSignal<number> = signal(0);
  
  requests: Array<any> = [];
 
  routeURL:WritableSignal<string> = signal('');
 
}
